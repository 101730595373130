import http from './http.js'

const api = {}

// 自定义请求
function request(url = '', params = {}, method = 'get') {
    url = 'https://zxcxappapi.cxbtv.cn/web/' + url
    if (method.toLowerCase() === 'get') {
        return http.get(url, {
            params: params,
        })
    } else if (method.toLowerCase() === 'post') {
        return http.post(url, params)
    }
}

api.article_index = (params) => request('article/index', params)
api.article_shizheng = (params) => request('article/shizheng', params)
api.article_list = (params) => request('article/list', params)
api.article_info = (params) => request('article/info', params)

api.toppic_index = (params) => request('toppic/index', params)

export default api
