import 'babel-polyfill'
import Es6Promise from 'es6-promise'
require('es6-promise').polyfill()
Es6Promise.polyfill()

import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false

// ElementUI
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

// 样式
import '@/style/style.less'
import '@/style/index.less'
import '@/style/article_list.less'
import '@/style/article_info.less'

// axios
import api from '@/api/api'
Vue.prototype.$api = api

// 使用 vue-meta
import Meta from 'vue-meta'
Vue.use(Meta)

new Vue({
    router,
    render: (h) => h(App),
}).$mount('#app')
